.login-browser-client {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 2rem;
    @include hds-breakpoint-up("sm") {
      min-width: $hds-breakpoint-sm;
    }
    @include hds-breakpoint-up("md") {
      padding: 0 6rem;
    }
  }

  &__header {
    margin-bottom: $hds-spacing-xl;
    > svg {
      fill: var(--hds-color-brand-300);
      width: 180px;
      height: auto;
    }

    > h1,
    > .hds-block-loader--title {
      margin: $hds-spacing-xl 0 $hds-spacing-sm;
      &.hds-heading--h1 {
        font-size: 2.25rem;
      }
    }

    > .hds-block-loader--title {
      height: $hds-font-size-h1;
      width: 100%;
    }
  }

  &__content {
    > .hds-text-input {
      margin-bottom: $hds-spacing-md;
    }

    > ul {
      display: flex;
      flex-direction: column;
      gap: $hds-spacing-sm;
      transition: height 0.2s ease;

      > .hds-block-loader--provider {
        height: 38px;
        width: 100%;
      }
    }

    &--org {
      min-height: 90px;
    }
  }

  &__back-btn {
    margin: $hds-spacing-xl 0 0;
    > .hds-button {
      transition: unset;
      width: auto;
    }
  }

  &__submit-email {
    > .hds-button {
      width: 100%;
      justify-content: center;
    }
  }

  &__auth-provider {
    > .hds-button {
      justify-content: center;
      width: 100%;
    }
  }

  &__footer {
    margin-top: $hds-spacing-xl;
    > ul {
      margin-bottom: $hds-spacing-xl;
      > li {
        display: flex;
        gap: 0.25rem;
        margin-bottom: $hds-spacing-2xs;
      }
    }
  }

  &__side-flair {
    @include hds-breakpoint-down("sm") {
      display: none;
    }
    @include hds-breakpoint-up("md") {
      height: 100%;
      width: 100%;
      background-image: url("../../static/images/bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: block;
    }
  }
}
